import React, { useState, useEffect } from "react";
import VCard from "vcard-creator";
import QRCode from "qrcode";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessCardAction } from "../../features/business-card/businessCardSlice";
import Spinner from "../../components/Spinner";
import {
  MainContainer,
  Nav,
  NameSection,
  Photo,
  DetailsContainer,
  Item,
  QR,
  SocialIcons,
  ErrorMessage
} from "./styled/BusinessCard";

import downloadIcon from "./img/download.svg";
import instagramIcon from "./img/instagram.svg";
import twitterIcon from "./img/twitter.svg";
import facebookIcon from "./img/facebook.svg";
import linkedinIcon from "./img/linkedin.svg";
import whatsappIcon from "./img/whatsapp.svg";

const BusinessCard = () => {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("serialNumber");
  const dispatch = useDispatch();

  const [qrUrl, setQrUrl] = useState("");
  const [downloadData, setDownloadData] = useState("");
  const [profileImageError, setProfileImageError] = useState(false);

  const { businessCard, errorGettingBusinessCard, isGettingBusinessCard } =
    useSelector((state) => state.businessCard);

  const socialIcons = {
    instagram: instagramIcon,
    x: twitterIcon,
    twitter: twitterIcon,
    facebook: facebookIcon,
    linkedin: linkedinIcon,
    whatsapp: whatsappIcon
  };

  function download(filename, text) {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/x-vcard;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  useEffect(() => {
    dispatch(getBusinessCardAction(serialNumber));
  }, [dispatch, serialNumber]);

  useEffect(() => {
    if (businessCard?.name) {
      const generateQR = async (text) => {
        try {
          const qr = await QRCode.toDataURL(text);
          setQrUrl(qr);
        } catch (err) {
          console.error(err);
        }
      };

      const {
        name = "",
        title = "",
        company = "",
        email = "",
        phone = ""
      } = businessCard;

      const street = businessCard.address?.addressLine1 || "";
      const worktown = businessCard.address?.city || "";
      const workpostcode = businessCard.address?.postCode || "";
      const country = businessCard.addAddress?.country || "";

      // Splitting the full name into first name, middle name, and last name
      const nameParts = name.split(" ");
      const firstName = nameParts.shift() || ""; // Get the first part as first name
      const lastName = nameParts.join(" "); // Join the remaining parts as last name

      const vCard = new VCard();
      vCard
        .addName(lastName, firstName, "", "", "")
        .addCompany(company)
        .addJobtitle(title)
        .addEmail(email)
        .addPhoneNumber(phone, "WORK")
        .addAddress("", "", street, worktown, "", workpostcode, country);

      generateQR(vCard.toString());
      setDownloadData(vCard.buildVCard());
    }
  }, [businessCard]);

  const removeProtocol = (url) => {
    return url.replace(/^https?:\/\//, "");
  };

  const addProtocolIfMissing = (url) => {
    return url.startsWith("http") ? url : `https://${url}`;
  };

  if (isGettingBusinessCard) {
    return <Spinner />;
  }

  // TODO: Update this after migration is complete
  if (errorGettingBusinessCard) {
    return (
      <ErrorMessage>
        Business card not available. Please try again later or contact support.
      </ErrorMessage>
    );
  }

  if (businessCard?.name) {
    return (
      <MainContainer>
        <DetailsContainer hasPhoto={businessCard?.photo && !profileImageError}>
          <Nav>
            {businessCard?.companyLogo ? (
              <img
                key={businessCard.companyLogo}
                src={`${businessCard.companyLogo}?${new Date().getTime()}`}
                alt="logo"
              />
            ) : (
              <div />
            )}
            <img
              style={{ cursor: "pointer" }}
              src={downloadIcon}
              alt="download"
              onClick={() =>
                download(
                  `${businessCard.name}-${businessCard.surname}`,
                  downloadData
                )
              }
            />
          </Nav>
          {businessCard?.photo && !profileImageError && (
            <Photo>
              <img
                key={businessCard.photo}
                src={`${businessCard.photo}?${new Date().getTime()}`}
                alt="user"
                onError={() => setProfileImageError(true)}
              />
            </Photo>
          )}
          <Item>
            <NameSection>
              <h2>
                {businessCard.name} {businessCard.surname}
              </h2>
              <p className="title-company">
                <span className="title">{businessCard?.title || ""}</span>
                {businessCard?.company && (
                  <span className="separator">{" • "}</span>
                )}
                <span className="company">{businessCard?.company || ""}</span>
              </p>
              <p className="company-website">
                {businessCard?.companyWebsite && (
                  <a
                    href={addProtocolIfMissing(businessCard?.companyWebsite)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {removeProtocol(businessCard?.companyWebsite)}
                  </a>
                )}
              </p>
            </NameSection>
          </Item>
          {businessCard?.email && (
            <Item>
              <p>Email</p>
              <span>{businessCard.email}</span>
            </Item>
          )}
          {businessCard?.phone && (
            <Item>
              <p>Phone number</p>
              <span>{businessCard.phone}</span>
            </Item>
          )}
          {Object.keys(businessCard.address).length !== 0 && (
            <Item>
              <p>Address</p>
              <div className="address-lines">
                <span>{businessCard.address?.addressLine1 || ""}</span>
                <span>{businessCard.address?.city || ""}</span>
                <span>{businessCard.address?.country || ""}</span>
                <span>{businessCard.address?.postCode || ""}</span>
              </div>
            </Item>
          )}

          {businessCard.social?.length > 0 && (
            <Item>
              <p>Social</p>
              <SocialIcons>
                {businessCard.social.map(
                  (social) =>
                    social?.link && (
                      <a
                        key={social.name}
                        href={social.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={socialIcons[social.name]} alt={social.name} />
                      </a>
                    )
                )}
              </SocialIcons>
            </Item>
          )}

          {qrUrl && <QR alt="qr" src={qrUrl} />}
        </DetailsContainer>
      </MainContainer>
    );
  }
  return null;
};

export default BusinessCard;

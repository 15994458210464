import styled, { createGlobalStyle } from "styled-components";
import velosansRegular from "./fonts/velosans-regular.woff2";
import velosansBold from "./fonts/velosans-bold.woff2";
import velosansMedium from "./fonts/velosans-medium.woff2";
import veloWWangleRegular from "./fonts/VeloWWangle-Regular.woff2";
import veloWWangleBold from "./fonts/VeloWWangle-Bold.woff2";
import veloWWangleMedium from "./fonts/VeloWWangle-Medium.woff2";

export const VeloFontStyle = createGlobalStyle`
@font-face {
  font-family: 'Velo WWangle';
  src: url(${veloWWangleRegular}) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Velo WWangle';
  src: url(${veloWWangleBold}) format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Velo WWangle';
  src: url(${veloWWangleMedium}) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Velo Sans';
  src: url(${velosansRegular}) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Velo Sans';
  src: url(${velosansMedium}) format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Velo Sans';
  src: url(${velosansBold}) format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  height: 100vh;
  background-color: #ffffff;

  .version,
  .serial-number {
    font-family: Velo Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  margin: auto;
  flex: 1;
  background-color: #ffffff;
`;

export const Button = styled.button`
  font-size: 12px;
  width: 50%;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: Velo WWangle;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 60px;
  position: relative;
  cursor: pointer;
  padding: 11px 18px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled(Button)`
  border: 2px solid #254aa2;
  background-color: #254aa2;
  color: #fff;
`;

export const SecondaryButton = styled(Button)`
  border: 2px solid #254aa2;
  background-color: #fff;
  color: #2b2b2b;
`;

export const Navbar = styled.nav`
  display: flex;
  justify-content: ${({ showBalance }) =>
    showBalance ? "space-between" : "center"};
  align-items: center;
  width: 100%;
  padding: 16px;
  gap: 32px;
  margin: 16px 0;

  .balance {
    font-family: Velo Sans;
    font-size: 16px;
    font-style: normal;
    max-width: 100%;
    text-align: right;
  }
`;

export const Content = styled.div`
  gap: 16px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* flex: 1; */

  img.background {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export const Alert = styled.div`
  max-width: 480px;
  position: absolute;
  background: rgba(240, 240, 240, 0.9);
  height: 100%;
  width: 100%;
  text-align: center;

  .inner-container {
    position: fixed;
    max-width: 343px;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 22px;
    border-radius: 20px;
    background-color: #fff;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;

    .close-button {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    p,
    h2,
    .subtitle,
    .info,
    .error {
      font-family: Velo Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    p,
    h2 {
      color: #2b2b2b;
      text-align: left;
    }

    .title {
      font-family: Velo Sans;
      font-size: 24px;
      font-style: normal;
      line-height: normal;
    }

    .subtitle {
      color: #2b2b2b;
    }

    .info,
    a,
    p,
    input {
      color: #2b2b2b;
    }

    .form,
    .inputs,
    button,
    .form-group {
      display: flex;
      flex-direction: column;
    }
    .form {
      gap: 30px;
    }

    .inputs {
      gap: 16px;
    }

    label {
      font-family: Velo Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
      margin-top: 16px;
    }

    input {
      font-size: 16px;
      ::placeholder {
        font-family: Velo Sans;
      }
      :focus {
        outline: none;
      }
      border-radius: 14px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 22px;
      background-color: #f0f0f0;
    }

    .error {
      color: red;
      font-size: 14px;
      margin-top: 8px;
    }

    .payment-details {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
`;
